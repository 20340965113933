import { useState } from "react";
import "../styles/navbar.css";
import React from "react";
import { Link } from "gatsby";

export default function Navigation() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    
    <nav className="navigation">
       <div className="logo">
          <Link to="/">
            <img
              src="/assets/img/slogo.png"
              alt=""
              className="img-fluid"
              id="logo" style={{position: "absolute", maxBlockSize: "-webkit-fill-available"}}
            />
          </Link>
        </div>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from Heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a href="/whyxccelerata">WHY XCCELERATA</a>
          </li>
          <li>
          <a href="/portfolio">PORTFOLIO</a>
          </li>
          <li>
          <a href="/entrepreneurs">ENTREPRENEURS</a>
          </li>
          <li>
          <a href="/partnerwithus">PARTNER WITH US</a>
          </li>
          <li>
          <a href="/investwithus">INVEST WITH US</a>
          </li>
          <li>
          <a href="/contact">CONTACT US</a>
          </li>
          <li>
              <Link to=" " className="noHover" style={{alignItems: "start", pointerEvents: "none", marginBottom: "6px", marginTop: "-10px"}}>XCONNECT Global Community</Link>
              
              <div style={{alignItems: "center", marginLeft: "15px", marginBottom: "10px", height: "70px",}}>
                <center>
                <Link to="https://xconnect.xccelerata.com/" target="_blank" className="page-button-head">
                  <b style={{color: "white", textAlign: "center", marginLeft: "5px"}}>Collaborate </b>
                </Link>
              </center>
              </div>
            </li>
        </ul>
      </div>
    </nav>
  );
}
