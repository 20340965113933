import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <>
      <footer id="footer">

        <div className="footer-top">

          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <img src="/assets/img/slogo.png" className="img-fluid" alt="" />
                <div className="social-links">
                  <a
                    href="https://twitter.com/xccelerata?t=fmLwRGJdRxvAT67k-xhPpw&s=09"
                    target="_blank"
                    className="twitter"
                  >
                    <i className="bi bi-twitter" style={{ color: "red" }}></i>
                  </a>
                  <a
                    href="https://www.facebook.com/pages/Xccelerata/100574034694507/"
                    target="_blank"
                    className="facebook"
                  >
                    <i className="bi bi-facebook" style={{ color: "red" }}></i>
                  </a>
                  <a
                    href="https://www.instagram.com/xccelerata_inc/"
                    target="_blank"
                    className="instagram"
                  >
                    <i className="bi bi-instagram" style={{ color: "red" }}></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/xccelerata/"
                    target="_blank"
                    className="linkedin"
                  >
                    <i className="bi bi-linkedin" style={{ color: "red" }}></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">

                <ul>
                  
                  <li>
                    
                      <b>Company</b>
                    
                  </li>
                  <li>
                    <Link to="/whyxccelerata">
                      Why Xccelerata
                    </Link>
                  </li>

                  <li>
                    <Link to="/portfolio">
                      Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">

                <ul>
                  <li>
                    
                      <b>Programs & Services</b>
                    
                  </li>
                  <li>
                    <Link to="/entrepreneurs">Entrepreneurs</Link>
                  </li>
                  <li>
                    <Link to="/partnerwithus">Partner With Us</Link>
                  </li>
                  <li>
                    <Link to="/investwithus">
                      Invest with Us
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/programs">Partner Ecosystem Program</Link>
                </li>
                <li>
                  <Link to="/programs">Talent Acquisition Program</Link>
                </li>
                <li>
                  <Link to="/programs">Customer Acquisition Program</Link>
                </li>
                <li>
                  <Link to="/programs">
                  Ongoing Support Program
                  </Link>
                </li>
                <li>
                  <Link to="/programs">Funding Acquisition Program</Link>
                </li> */}
                </ul>
              </div>


              <div className="col-lg-3 col-md-6 footer-links">
                <ul>
                  <li>
                    
                      <b>Get In Touch</b>
                    
                  </li>
                  <li>
                  <strong>Phone:</strong><a style={{color: "white"}} href="tel:+1-833-207-2727"><b>+1-833-207-ASAP</b> </a>

                      <br />
                      <a style={{color: "white", marginLeft: "53px"}} href="tel:+1-833-207-2727"><b>+1-833-207-2727</b></a>
                      <br />
                    <p>
                      Suite 501, 218 Export Blvd. <br />
                      Mississauga, ON, L5S 0A7
                     
                      Canada <br /> 
                      
                      <strong>Email: </strong><a style={{color: "white"}} href="mailto:contact@xccelerata.com">contact@xccelerata.com</a> 
                      <br />
                    </p>
                  </li>
                </ul>

              </div>


            </div>
          </div>
          {/* <div
            className="copyright">
            &copy; Copyright <strong>Xccelerata Inc.</strong>. All Rights Reserved
          </div> */}
        </div>

        <div className="footer-new">
          <div
            className="copyright">
            &copy; Copyright <strong>Xccelerata Inc.</strong>. All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  )
}
